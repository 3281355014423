/**
 * Interface for the 'University' data
 */
// Response from the API
import { ClientFeatureOptions } from './client-features';
import { FAQ } from './faq.models';
import { UppercaseProduct } from './product.models';

export interface UniversityResponse {
	university: UniversityProfile;
	universityGallery: UniversityGallery;
	universitySections: UniversitySections;
}
export interface ClearingUniversityResponse {
	clearingUniversity: ClearingUniversityProfile;
}

export interface UpdateUniversityResponse {
	universitySections: {
		overview: string;
	};
}

export interface UniversitySectionsResponse {
	universitySections: UniversitySections;
}

export interface UniversityEventResponse {
	universityEvents: UniversityEvent[];
	university: UniversityProfile;
}

export interface UniversityCampusLocationsResponse {
	universityCampuses: Campus;
}

export interface AccommodationResponse {
	universityAccommodations: Accommodation;
}

export interface UniversityRatingStatsResponse {
	universityRatings: UniversityRatingStats;
}

export interface UniversityReviewsResponse {
	universityReviews: UniversityReviews;
}

export interface UniversityReviewResponse {
	universityReview: UniversityReview;
}

// End Response from the API
export interface UniversityProfile extends University {
	universityGallery: UniversityGallery;
	universitySections: UniversitySections;
}

export interface ClearingUniversityProfile {
	id: number;
	name: string;
	logo: string;
	address: Address;
	paid_features: ClientFeatureOptions[];
	stats: UniversityStatistics;
	seo: UniversitySeo;
	phone_number: string;
	external_url: string;
	privacy_policy_url: string | null;
	saved: boolean;
	slug: string;
	cover_image: string;
	gallery: Image[];
	overview: string;
	usp: string;
	video: ClearingUniversityVideo;
	faqs: FAQ[];
	order: number;
}

export interface University {
	id: number;
	name: string;
	logo: string;
	address: Address;
	courses_count: number;
	paid_features: ClientFeatureOptions[];
	saved: boolean;
	overview: string;
	external_events_url: string;
	privacy_policy_url: string;
	stats: UniversityStatistics;
	slug: string;
	seo: UniversitySeo;
	order: number | null;
	undergraduate: UniversityProductProfileDetails;
	postgraduate: UniversityProductProfileDetails;
	faqs: FAQ[];
}

export interface UniversityProductProfileDetails {
	cover_image: string;
	external_prospectus_url: string | null;
	external_url: string;
}

export interface Address {
	street: string;
	postcode: string;
	maps: string;
	region: string;
}
export interface UniversityStatistics {
	reviews_count: number;
	total_students: number;
	full_time_students_percentage: number;
	apprenticeship_students: number;
	rank_position: number;
	recommended_percentage: number;
	rating: number;
	students_in_hall: number;
	average_entry_tariff: number;
}
export interface UniversitySeo {
	title: string;
	description: string;
	keywords: string;
}
export interface UniversityGallery {
	images: Image[];
	video: Video;
}

export interface Gallery {
	cover_image: string;
	images: Image[];
	video?: ClearingUniversityVideo;
}

export interface Image {
	path: string;
	position?: number;
}

export interface Video {
	url: string;
	thumbnail: string;
}

export interface UniversitySections {
	overview: string;
	external_campus_url: string;
	external_tuition_fees_url: string;
	external_information_url: string;
	job_prospects: ImageAndText;
	courses_lectures: ImageAndText;
	accommodation: ImageAndText;
	city_life: ImageAndText;
	student_support: ImageAndText;
	student_life: ImageAndText;
	university_facilities: ImageAndText;
	clubs_societies: ImageAndText;
	student_union: ImageAndText;
	tuition_fees: ImageAndText;
	scholarships_bursaries: ImageAndText;
}

export interface UniversitySection {
	[key: string]: ImageAndText;
}

export interface ImageAndText {
	image: string | null;
	text: string;
	label?: string;
}

export interface UniversityEvent {
	id: number;
	description: string;
	date: string;
	type: string;
	external_url: string;
	subtitle: string;
	is_recommended: boolean;
	product: UppercaseProduct;
	campus: string;
}

export interface Campus {
	locations: CampusLocations[];
	external_url: string;
}

export interface CampusLocations {
	name: string;
	description: string;
	image: string;
}

export interface Accommodation {
	rank: number;
	rating: number;
	external_url: string;
	locations: AccommodationLocations[];
}

export interface AccommodationLocations {
	name: string;
	price: string;
	total_rooms: number;
	postcode: string;
	image: string | null;
}

export interface UniversityRatingStats {
	accommodation: string;
	graduate_careers: string;
	course_quality: string;
	social_life: string;
	stars: UniversityStars;
}

export interface UniversityReviews {
	data: UniversityReview[];
	top: UniversityReview;
	per_page: number;
	current_page: number;
	from: number;
	to: number;
	has_more_pages: boolean;
}

export type UniversityReviewsUnion = UniversityReviewResponse | UniversityReviews;

export interface UniversityStars extends Record<string, number> {
	one: number;
	two: number;
	three: number;
	four: number;
	five: number;
}

export interface ReversedStars {
	key: string;
	value: number;
}

export interface UniversityReviews {
	data: UniversityReview[];
	top: UniversityReview;
	total: number;
	per_page: number;
	current_page: number;
	from: number;
	to: number;
	has_more_pages: boolean;
}

export interface UniversityReview {
	id: number;
	title: string;
	rating: number;
	avatar: string | null;
	username: string;
	subject: string | null;
	published_at: string;
	comment: string;
	likes: number;
	dislikes: number;
	reply: string | null;
	reaction: ReviewReactionOptions;
}

export type ReviewReactionOptions = null | 'LIKE' | 'DISLIKE';

export enum UniversityUrlPath {
	undergraduate = 'undergraduate',
	postgraduate = 'postgraduate',
	clearing = 'clearing',
	openDays = 'open-days',
	reviews = 'reviews',
}

export interface UniversitySectionLabel {
	[key: string]: string;
}

export interface ClearingUniversityVideo {
	url: string;
	thumbnail: string;
}
