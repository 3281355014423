import { FeaturedPostgraduate, FeaturedUndergraduate } from './client-features';
import { Product, ProductId } from './product.models';

export interface LeadsHeaderData {
	title: string;
	subtitle: string;
	description: string;
	success: boolean;
}

export interface CustomChoiceOptions {
	options: (string | number | { id: number; name: string })[];
	shortTitle: string;
	longTitle?: string;
	formControlName: string;
	controlName: string;
}

export interface CustomSelectOptions extends CustomChoiceOptions {
	key: string;
	isArrayOfObjects: boolean;
}

export interface RegisterLeadsData {
	uniId: number;
	email: string;
	firstname: string;
	lastname: string;
	enquiry: string;
	startYear: number;
	degree: string;
	recaptcha_token: string;
	product: ProductId;
}

export interface RegisterLeadsResponse {
	uniId: number;
	uniName: string;
	uniLogo: string;
	uniRecommended: number | undefined;
	uniCoverImage: string | undefined;
	externalLink: string;
	prospectusExternalLink: string;
	userEmail: string;
	success?: boolean;
	ugSearchOrderPosition: number | null;
	isFeaturedUndergraduate: FeaturedUndergraduate;
	isFeaturedPostgraduate: FeaturedPostgraduate;
	product: Product;
}

export type SourceTrackingTypes = 'uni' | 'course' | '';

export enum LeadsEnum {
	Prospectus = 'PROSPECTUS',
	Request = 'REQUEST',
	RegisterInterest = 'REGISTER_INTEREST',
	Call = 'CALL',
}

export type LeadsType = `${LeadsEnum}`;

export interface BigDataVerificationError {
	errorCode?: 'INVALID_PARAMETER';
	message?: `${BigDataVerificationErrorMessage}`;
}

export enum BigDataVerificationErrorMessage {
	INVALID_EMAIL = 'invalid email',
	INVALID_PHONE = 'invalid phone number',
}
