import { Product, ProductEnum, ProductId } from '@uc/web/shared/data-models';

export const isValidProduct = (product: any) => {
	const validProducts: Product[] = [
		ProductEnum.Undergraduate,
		ProductEnum.Postgraduate,
		ProductEnum.Clearing,
	];

	return validProducts.includes(product);
};

export const getProductById = (productId: any): Product => {
	switch (productId) {
		case 1:
			return ProductEnum.Undergraduate;
		case 2:
			return ProductEnum.Postgraduate;
		case 3:
			return ProductEnum.Clearing;
		default:
			return ProductEnum.Undergraduate;
	}
};

export const getIdByProduct = (product: Product): ProductId => {
	const products: Product[] = [ProductEnum.Undergraduate, ProductEnum.Postgraduate];

	const productId = products.indexOf(product) + 1;

	return productId as ProductId;
};
