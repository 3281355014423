import { SourceTrackingTypes } from '@uc/web/shared/data-models';

/**
 * @param source e.g. "Coventry University"
 * @param sourceType either "uni" or "course" (or empty string)
 * @returns e.g. "Coventry University Profile" or "Law Course Profile"
 */
export const configureSource = (
	source: string,
	sourceType: SourceTrackingTypes,
): string => {
	if (!source) return 'University Profile';

	if (sourceType === 'course') {
		return `${source} Course Profile`;
	} else {
		if (source.toLowerCase().includes('university')) {
			return `${source} Profile`;
		} else {
			return `${source} University Profile`;
		}
	}
};
